//t = current time
//b = start value
//c = change in value
//d = duration

Math.easeInOutQuad = function (t, b, c, d) {

    t /= d/2

    if (t < 1) return c/2*t*t + b

    t--

    return -c/2 * (t*(t-2) - 1) + b
}

export const horizontalScrollTo = function( element, distance, duration ) {

    const to = element.scrollLeft + distance
    const start = element.scrollLeft
    const change = to - start
    let currentTime = 0
    let increment = 20

    const animate = function() {

        currentTime += increment

        const val = Math.easeInOutQuad( currentTime, start, change, duration )

        element.scrollLeft = val

        if(currentTime < duration) {
            setTimeout(animate, increment)
        }

    }

    animate()

}

export const verticalScrollTo = function( element, distance, duration ) {

    const to = element.scrollTop + distance
    const start = element.scrollTop
    const change = to - start
    let currentTime = 0
    let increment = 20

    const animate = function() {

        currentTime += increment

        const val = Math.easeInOutQuad( currentTime, start, change, duration )

        element.scrollTop = val

        if(currentTime < duration) {
            setTimeout(animate, increment)
        }

    }

    animate()

}