export const isExpired = function( now, past ) {

    // oauth exp is already in seconds

    const cur = Math.floor((now/1000) % 60) // current time from miliseconds to seconds

    let diference = ( past - cur ) / 1000

    diference /= 60

    const currentDiff = Math.round( diference )

    if ( currentDiff <= 0 ) return true

    return false

}