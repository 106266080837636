<template>

    <div class="w-full 
                relative
                cursor-pointer
                select-none
                outline-none"

    tabindex="0"

    :class="{
        'opacity-75': disabled,
        'pointer-events-none': disabled
    }"

    v-out="closeList"

    @focus="setFocus"
    @blur="(searchable && list.length > 10) ? openList ? false : removeFocus() : removeFocus()"

    @keydown="handleKeys"

    >

        <label class="text-sm text-gray-600 block py-2 opacity-0" :class="{ 'opacity-100': label }" v-if="showLabel" >{{label}} <span class="text-red-500" >{{ required ? '(required)' : null }}</span></label>
    
        <!-- Select head -->

        <div class="select-input
                    w-full 
                    px-4
                    flex
                    text-sm
                    space-x-4
                    relative
                    items-center 
                    cursor-pointer
                    rounded-md
                    outline-none
                    overflow-hidden" 
        
        :style="computedHeight"
        :class="{
            'bg-white': !naked,
        }" 

        @click="toggleList"
        
        >

            <p class="mr-2" v-if="prefix" >{{ prefix }}</p>

            <p 
            
            class="truncate text-gray-500"

            :class="{
                'flex-grow': !naked,
                'text-ch-blue': input || input === 0 ? returnId ? input || input === 0 : input.length > 0 : false,
                'text-ch-light-blue': (input || input === 0 ? returnId ? input || input === 0 : input.length > 0 : false) && color
            }"
            
            >

                {{

                    input || input === 0 ?
                    returnId && !multiple ?
                    list.find( i => i.id === input )?.name :
                    returnId && multiple && input.length > 0 ?
                    selectedList.map( i => i.name ).join(', ') : 
                    input.length > 0 ?
                    input.map( v => v.name ).join(', ') : 
                    placeholder : 
                    placeholder

                }}
            
            </p>

            <p v-if="showCount" class="text-gray-600 whitespace-no-wrap" :class="{ 'text-yellow-500': color }" >
                
                <span class="text-green-500" v-if="multiple" >{{ selectedList.length }}</span> 
                <span v-if="multiple" > / </span> 
                {{ count }}
                
            </p>

            <img src="/images/icons/chevron-bottom.svg" alt="arrow" >

        </div>

        <!-- Select body -->

        <div class="w-full 
                    absolute 
                    left-0
                    z-30 
                    bg-white 
                    shadow-xl 
                    rounded-md
                    overflow-hidden
                    transition-height"
                     
        :style="computedListStyles"
        :class="{ 'duration-200': openList }"
        
        >

            <transition-group 
            
            tag="div"
            name="show-list"
            class="select-input-list"

            @after-enter="scrollList"
            
            :style="computedListStyles" 
            :class="{ 'overflow-y-auto': list ? list.length > 4 : false }" 
            
            >

                <!-- Search List -->

                <div :key="0" class="h-11 sticky top-0 z-10 px-4 flex space-x-4 bg-white border-b border-solid border-gray-200" v-show="list ? ( searchable && list.length > 10 ) : false" >

                    <input 
                    
                    :tabindex="openList ? 1 : -1"

                    placeholder="Пребарувај"
                    class="text-sm w-full h-full outline-none border-0" 

                    v-model="search"

                    @focus="focus = true"

                    >

                    <img src="/images/icons/search.svg" class="opacity-50" alt="search" >

                </div>

                <!-- Select body item -->

                <div class="list-item
                            h-11
                            px-4
                            flex
                            text-sm
                            space-x-4
                            items-center
                            hover:bg-gray-100"
                
                :key="index + 1" v-for="(item, index) in searchableList"
                :selected="selectedList.map( i => i.id ).includes(item.id)"
                :preselected="item.id === preselectedItem"
                :class="{ 
                    'font-bold': selectedList.map( i => i.id ).includes(item.id),
                    'bg-gray-100': item.id === preselectedItem,
                    'preselected': item.id === preselectedItem
                }"

                v-show="openList"

                @click="selectItem(item)"
                
                >

                    <p class="flex-grow truncate" :title="item.name" >{{ item.name }}</p>

                    <img 
                    
                    alt="checkmark"
                    src="/images/icons/circle-checked.svg" 
                    
                    :class="{ 'opacity-25': !selectedList.map( i => i.id ).includes(item.id) }"

                    v-if="showBadge"

                    >

                </div>

            </transition-group>

        </div>

    </div>

</template>

<script>

    import { ref, watch, computed, onMounted } from 'vue'

    import { out } from '@/assets/scripts/clickOutside'
    import { verticalScrollTo } from '@/assets/scripts/scrollTo'

    export default {
    
        name: 'Select',

        props: {

            // list prop must recive Array of objects eg: [{ id: 1, name: Item }]

            list: Array,
            label: String,
            color: Boolean,
            naked: Boolean,
            prefix: String,
            required: Boolean,
            returnId: Boolean,
            multiple: Boolean,
            disabled: Boolean,
            showCount: Boolean,
            preselected: Boolean,
            modelValue: [Array, String, Number],
            height: { type: Number, default: 48 },
            showBadge: { type: Boolean, default: true },
            showLabel: { type: Boolean, default: true },
            searchable: { type: Boolean, default: true },
            placeholder: { type: String, default: 'Избери Артикл' }

        },

        setup( props, { emit }) {

            // Accesibility

            const focus = ref(false)
            const element = ref(null)

            const setFocus = () => {

                focus.value = true

            }

            const removeFocus = () => {

                focus.value = false

            }

            const preselectedItem = ref(0)

            const preselect = () => preselectedItem.value = searchableList.value[0]?.id

            const scrollToPreselectedDown = () => {

                let parent

                if ( props.searchable && props.list.length > 10 ) parent = document.activeElement.parentElement.parentElement
                else parent = document.activeElement.querySelector('.select-input-list')

                const item = parent.querySelector('.preselected')

                const offsetTop = item.offsetTop
                const scrollTop = parent.scrollTop

                verticalScrollTo( parent, (offsetTop - scrollTop) - 88, 200 )

            }

            const scrollToPreselectedUp = () => {

                let parent

                if ( props.searchable && props.list.length > 10 ) parent = document.activeElement.parentElement.parentElement
                else parent = document.activeElement.querySelector('.select-input-list')
                
                const item = parent.querySelector('.preselected')

                const offsetTop = item.offsetTop
                const scrollTop = parent.scrollTop

                verticalScrollTo( parent, (offsetTop - scrollTop), 200 )

            }

            const handleKeys = (e) => {

                switch( e.keyCode ) {

                    case 9:
                        closeList()
                        break

                    case 13:
                        if ( !openList.value ) toggleList()
                        else searchableList.value.length > 0 ? selectItem( props.list.find( item => item.id === preselectedItem.value ) ) : false
                        break;

                    case 27:
                        closeList()
                        break;

                    case 38:

                        e.preventDefault()

                        for ( let n in searchableList.value ) {

                            const index = parseInt(n)
                            const prevIndex = index - 1

                            const id = searchableList.value[index].id
                            const prevId = searchableList.value[prevIndex]?.id

                            if( id === preselectedItem.value ) {

                                if ( index === 0 ) preselectedItem.value = searchableList.value[searchableList.value.length - 1].id
                                else preselectedItem.value = prevId

                                scrollToPreselectedDown()

                                break

                            }

                        }

                        break;

                    case 40:

                        e.preventDefault()
                        
                        if ( !openList.value ) {

                            toggleList()
                            preselect()

                        }

                        else {

                            for( let i in searchableList.value ) {

                                const index = parseInt(i)
                                const nextIndex = index + 1
                        
                                const id = searchableList.value[index].id
                                const nextId = searchableList.value[nextIndex]?.id

                                if ( id === preselectedItem.value ) {

                                    if( index === searchableList.value.length - 1 ) preselectedItem.value = searchableList.value[0].id
                                    else preselectedItem.value = nextId

                                    scrollToPreselectedUp()

                                    break

                                }

                            }

                            break

                        }

                }

            }

            const scrollList = () => {

                if ( openList.value ) {

                    // Fix list not scrolling to top when mounted

                    const el = document.activeElement.parentElement.parentElement
                    el.scrollTop = 0

                }

            }

            // Select Head

            const count = computed(() => props.list ? searchableList.value.length : 0 )
            const computedHeight = computed(() => `height: ${props.height}px` )

            const input = computed({
                get: () => props.modelValue,
                set: value => emit( 'update:modelValue', value )
            })

            // Select Body | List 

            const openList = ref(false)
            const closeList = () => { 
                focus.value = false
                openList.value = false 
            }

            const toggleList = () => {

                openList.value = !openList.value

                preselect()

                element.value = document.activeElement
                const searchInput = element.value.getElementsByTagName('input')[0]

                if ( openList.value ) {

                    if ( props.searchable && props.list.length > 10 ) searchInput.focus()

                }

                else searchInput.blur()

            }

            const search = ref('')
            const searchableList = computed(() => props.list ? props.list.filter( i => i.name.match(new RegExp( search.value, 'i' )) ) : [] )

            watch( search, () => { preselect() })

            const computedListStyles = computed(() => { 
                
                return  `top: ${ props.showLabel ? props.height + 45 : props.height + 5 }px;` +
                        `height: ${ ( props.list && openList.value ) ? searchableList.value.length > 5 ? 220 : ( 44 * ( props.list.length > 10 ? searchableList.value.length + 1 : searchableList.value.length ) ) : 0 }px;` 
            
            })

            const selectedList = ref([])

            const selectItem = (item) => {

                if ( props.multiple ) {

                    if ( selectedList.value.map( i => i.id ).includes( item.id ) ) {

                        selectedList.value = selectedList.value.filter( i => i.id != item.id )

                    }

                    else {

                        selectedList.value.push( item )

                        preselectedItem.value = item.id

                    }

                }

                else {

                    if ( selectedList.value.map( i => i.id ).includes( item.id ) ) {

                        selectedList.value = []

                    }

                    else {

                        selectedList.value = []
                        selectedList.value.push(item)

                        preselectedItem.value = item.id

                        closeList()

                    }

                }

                emit( 'update:modelValue', props.returnId ? props.multiple ? selectedList.value.map( i => i.id ) : selectedList.value[0]?.id : selectedList.value )

            }

            const preselectItem = () => {

                if ( props.preselected ) {

                    selectedList.value.push( props.list[0] )

                    emit( 'update:modelValue', props.returnId ? props.multiple ? selectedList.value.map( i => i.id ) : selectedList.value[0]?.id : selectedList.value )

                }

            }

            // Validation

            const addValuesOnMount = () => {

                if ( input.value ) {

                    if ( props.returnId ) {

                        if ( props.multiple ) {

                            input.value.forEach( val => {

                                const item = props.list.find( i => i.id === val ) 
                                selectedList.value.push(item)

                                preselectedItem.value = item.id

                            })

                        }

                        else {

                            const item = props.list.find( i => i.id === input.value ) 

                            if ( item ) {
                                selectedList.value.push(item)
                                preselectedItem.value = item.id
                            }

                        }

                    }

                    else selectedList.value = input.value

                }
                

            }

            const valid = ref(false)

            const validate = () => {

                if ( props.required ) {

                    if ( input.value ) valid.value = true 
                    else valid.value = false

                }

                else valid.value = true

                emit( 'valid', valid.value )  

            }

            onMounted(() => {
                
                addValuesOnMount()
                preselectItem()
                validate()

            })

            // Immediate watch for validation

            watch( input, () => { 

                validate()
                // addValuesOnMount()

            }, {immediate: true})

            // Emit Update if input is changed and valid

            watch( input, () => { 

                search.value = ''

                if ( valid.value ) emit('update')

            })

            // Reset input if list is changed

            watch( searchableList, () => {

                input.value = null

            })

            return {
                count,
                input,
                valid,
                focus,
                search,
                setFocus,
                openList,
                closeList,
                scrollList,
                toggleList,
                selectItem,
                handleKeys,
                removeFocus,
                selectedList,
                computedHeight,
                searchableList,
                preselectedItem,
                computedListStyles
            }

        },

        directives: { out }

    }

</script>

<style scoped>

    .select-input-list {

        overflow-anchor: none;
    }

    .show-list-enter-active, .show-list-leave-active {

        transition: 0s;
    }

    .show-list-leave-to {

        transform: translateY(2rem);
        opacity: 0;
    }

    .show-list-enter-from {

        transform: translateY(-2rem);
        opacity: 0;
    }

</style>