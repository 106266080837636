<template>
    
    <div class="px-2 md:px-4 mt-4" >

        <!-- Table Head -->

        <div class="w-full h-12 text-sm grid grid-cols-14 items-center px-2 border-b border-solid border-gray-300" >

            <h2 class="text-sm md:text-base text-i-blue font-bold col-span-10 md:col-span-8" >БИЛАНС НА СОСТОЈБА</h2>

            <p class="text-right col-span-2 hidden md:block" >{{ year ? year[0].name : null }}</p>

            <p class="text-right col-span-2 hidden md:block" >{{ year ? parseInt(year[0].name) - 1 : null }}</p>

            <p class="text-right col-span-4 md:col-span-2" >Промена во %</p>

        </div>

        <div class="table-row" :class="{'table-row-main': item.main }" :key="item.aop" v-for="item in sostojba" >

            <div class="col-span-11 md:col-span-8" >

                <p class="truncate" :title="item.naziv" >{{ item.naziv }}</p>

                <p class="text-gray-500 md:hidden" >{{ formater.format( item ? item[year[0].key] : null ) }}</p>

            </div>

            <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( item?.tekovnaGodina ) }}</p>

            <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( item?.prethodna ) }}</p>

            <p 
            
            class="text-right col-span-3 md:col-span-2" 
            
            :class="{ 
                'text-i-blue': item?.promenaProcent >= 0,
                'text-i-red': item?.promenaProcent < 0 
            }" 
            
            >
            
                {{ item?.promenaProcent > 0 ? '+ ' + item?.promenaProcent : item?.promenaProcent }} %
            
            </p>

        </div>

    </div>

</template>

<script>

    export default {
    
        name: 'Bilans na Uspeh',

        props: {
            year: Object,
            sostojba: Array
        },

        setup() {

            const formater = new Intl.NumberFormat('mk-MK')

            return {
                formater
            }

        }

    }

</script>