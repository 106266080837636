export const formatTitle = ( title ) => {

    if ( typeof title !== 'string' ) return title

    const words = title.split(' ')
    const formatedWords = []

    words.forEach( word => {

        let formatedWord = ''

        formatedWord = word.toLowerCase()
    
        const firstChar = formatedWord[0].toUpperCase()

        formatedWord = firstChar + formatedWord.substring(1)

        formatedWords.push(formatedWord)

    })

    return formatedWords.join(' ')

}

export const cleanTitles = (data) => {

    if ( data && data.length > 0 ) {

        data.forEach( entry => {

            entry.main = false

            let cleanTitle

            if ( entry.naziv ) {

                // Remove roman numerals in front of the string

                let title = entry.naziv.split('.')

                if ( title.length > 1 ) {

                    const matchString = 'IIIVIIIXIII'

                    if ( matchString.match(title[0]) ) {

                        title.shift()

                        cleanTitle = title.join(' ')

                        entry.main = true

                    }

                    else cleanTitle = title.join(' ')

                }

                else cleanTitle = title[0]

                // Remove the formula after the string

                const removeFormula = cleanTitle.split('(')

                if ( removeFormula.length > 1 ) {

                    if ( removeFormula[1].match(/\d/g) ) {

                        cleanTitle = removeFormula[0]

                    }

                    else cleanTitle = removeFormula.join(' (')

                }

                // Add main true to all upperCase strings

                if ( cleanTitle === cleanTitle.toUpperCase() ) entry.main = true

                entry.naziv = cleanTitle

            }

        })

    }

    //return data

}