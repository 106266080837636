import { createStore } from 'vuex'
import { filters } from './modules/filters'
import { companies } from './modules/companies'

import { axios } from '@/plugins/axios'

export default createStore({

    state: {

        auth: null,
        user: null,
        role: 'guest',
        userEmail: null,
        banner: false,
        header: true,

    },

    mutations: {

        setAuth( state, auth ) { state.auth = auth },
        setUser( state, user ) { state.user = user },
        setRole( state, role ) { state.role = role },
        setBanner( state, banner ) { state.banner = banner },
        setUserEmail( state, email ) { state.userEmail = email },
        setHeader( state, header ) { state.header = header }

    },

    actions: {

        async fetch( ctx, config ) {

            const token = ctx.state.auth
            if (token) config.headers = { ...config.headers, ...{ Authorization: `Bearer ${token}` } }


            let errors
            let payload
            let headers

            const genericError = [{
                key: null,
                value: 'Something went wrong'
            }]

            try {

                const response = await axios(config)

                const status = response.status
                // const url = config.url

                if ( status != 200 ) {

                    console.log( 'fetch response | status != 200:', response )

                    if (response.errors) errors = response.errors
                    else if (response.data.errors) errors = response.data.errors
                    else if (response.data.message && !response.data.details) errors = [{ key: null, value: response.data.message }]
                    else if (response.data.message && response.data.details) errors = [{ key: null, value: response.data.message }, { key: 'Reason', value: response.data.details.join(', ') }]
                    else if (typeof response.data === 'string' && response.data) errors = [{ key: null, value: response.data }]
                    else errors = genericError

                    payload = null
                    headers = response.headers

                }

                else {

                    errors = null
                    payload = response.data ? response.data : 'Success',
                    headers = response.headers

                }

                // console.log({
                //     url,
                //     errors,
                //     payload,
                //     status
                // })

                return {
                    errors,
                    payload,
                    headers
                }

            }

            catch (ex) {

                console.log( 'fetch exception:', ex )

                return {
                    errors: [{ key: null, value: ex.message }],
                    payload: null,
                    headers: null
                }
            }

        },

        async changePassword({ state, dispatch }) {

            const changePass = await dispatch('fetch', {

                method: 'POST',
                url: 'https://intela.eu.auth0.com/dbconnections/change_password',
                data: {
                    client_id: "emQm5k4Q00zgsEnelJhaVc88DuYsIkki",
                    email: state.userEmail,
                    connection: 'Username-Password-Authentication'
                }

            })

            return changePass

        }

    },

    modules: {
        filters,
        companies
    }

})