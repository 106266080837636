<template>
    
    <div class="w-full h-headless md:grid md:grid-cols-2" >

        <div class="bg-center bg-no-repeat bg-cover" style="background-image: url('/images/profile.jpg')" ></div>

        <div class="h-full bg-white grid content-center" >

            <div class="flex flex-col space-y-2 items-center justify-center md:py-4" >

                <img src="/images/icons/user_big.svg" alt="logo" >

                <h2 class="text-lg md:text-2xl tracking-wide" >Вашиот Профил</h2>

            </div>

            <form class="w-full max-w-lg mx-auto px-10 py-4 grid gap-4" >

                <div class="bg-gray-200 rounded-md flex px-4" >

                    <input 
                
                    type="text" 
                    placeholder="Емаил Адреса"

                    :value="$store.state.userEmail"
                    readonly

                    class="text-gray-600 border-none outline-none px-0 bg-transparent"

                    >

                    <img src="/images/icons/edit.svg" alt="edit" >

                </div>

                <div 
                
                class="p-4 text-sm rounded-lg" 

                :class="{
                    'bg-teal-200': success,
                    'text-teal-600': success,
                    'bg-red-200': error,
                    'text-i-red': error
                }"
                
                v-if="error || success" 
                
                >

                    <p>{{ error || success }}</p>

                </div>

                <button class="btn mt-4" @click.prevent="changePassword" >Промени Лозинка</button>

            </form>

        </div>

    </div>

</template>

<script>

    import { ref } from 'vue'
    import { useStore } from 'vuex'

    export default {

        name: 'Profile',

        setup() {

            const store = useStore()

            const error = ref('')
            const success = ref('')

            const changePassword = async () => {

                const password = await store.dispatch('changePassword')

                if ( password.errors ) {

                    success.value = ''
                    error.value = password.errors.map( e => e.value ).join(', ')

                }

                else {
                    
                    error.value = ''
                    success.value = `Ви испративме порака на ${store.state.userEmail} за да ја ресетирате лозинката.`

                }

            }

            return {
                error,
                success,
                changePassword
            }

        }

    }

</script>
