import createAuth0Client from '@auth0/auth0-spa-js'

export const auth0 = async (options) => {

    options ? options : options = {}

    const auth = await createAuth0Client({
        domain: 'intela.eu.auth0.com',
        client_id: 'emQm5k4Q00zgsEnelJhaVc88DuYsIkki',
        redirect_uri: window.location.origin,
        useRefreshTokens: true
    })

    return auth

}

export const auth0Plugin = {
    
        install(app, options) {

        options ? options : options = {}

        const auth = auth0(options)

        app.config.globalProperties.$auth = auth

        app.provide( 'auth', auth )

    }

}