export const downloadFile = function( blob, fileName ) {

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.setAttribute('style', 'display: none')
    a.href = url
    a.download = fileName
    a.click()
    a.remove()

    setTimeout(function () {
        window.URL.revokeObjectURL(url)
    }, 1000)

}