export const companies = {

    state: {

        page: 1,
        pages: 0,
        count: 0,
        query: '',
        companies: []

    },

    mutations: {

        setPage(state, page) { state.page = page },
        setPages(state, pages) { state.pages = pages },
        setCount(state, count) { state.count = count },
        setQuery(state, query) { state.query = query },
        setCompanies(state, companies) { state.companies = companies }

    },

    actions: {

        async getCompanyDetails({ dispatch }, id) {

            const details = await dispatch('fetch', {

                url: `api/company/${id}`,
                withCredentials: true

            })

            return details

        },

        async exportCompanyDetails({ dispatch }, options ) {

            const type = options.type === 1 ? 'skraten' : 'zavrshna'

            const details = await dispatch('fetch', {

                url: `api/company/${ options.id }?export=true&type=${type}`,
                responseType: 'blob',
                withCredentials: true

            })

            return details

        },

        async search({ dispatch }, data) {

            const results = await dispatch('fetch', {

                url: `api/companies/search?json=${encodeURIComponent(JSON.stringify(data.params))}`,
                withCredentials: true

            })

            return results

        },

        async exportSearch({ dispatch }, data) {

            const results = await dispatch('fetch', {

                url: `api/companies/search?json=${encodeURIComponent(JSON.stringify(data.params))}&export=true`,
                responseType: 'blob',
                withCredentials: true

            })

            return results

        },

    }

}