export const filters = {

    state: {

        filters: [

            {
                id: 0,
                data: [],
                range: { from: null, to: null },
                showCurrency: false,
                selectedList: [],
                type: 'table',
                name: 'region',
                key: 'region',
                display: 'Град / Реон'
            },
            {
                id: 1,
                data: [],
                groupsData: [],
                range: { from: null, to: null },
                showCurrency: false,
                selectedList: [],
                type: 'select',
                name: 'industry',
                key: 'industry',
                display: 'Индустрија'
            },
            {
                id: 2,
                data: null,
                range: { from: null, to: null },
                showCurrency: true,
                selectedList: [],
                type: 'range',
                name: 'income',
                key: 'income',
                display: 'Приход'
            },
            {
                id: 3,
                data: null,
                range: { from: null, to: null },
                showCurrency: true,
                selectedList: [],
                type: 'range',
                name: 'profit',
                key: 'profit',
                display: 'Профит'
            },
            {
                id: 4,
                data: null,
                range: { from: null, to: null },
                showCurrency: false,
                selectedList: [],
                type: 'range',
                name: 'numberOfEmployees',
                key: 'numberOfEmployees',
                display: 'Број на вработени'
            },
            {
                id: 5,
                data: null,
                range: { from: null, to: null },
                showCurrency: true,
                selectedList: [],
                type: 'range',
                name: 'requests',
                key: 'requests',
                display: 'Побарувања'
            },
            {
                id: 6,
                data: null,
                range: { from: null, to: null },
                showCurrency: true,
                selectedList: [],
                type: 'range',
                name: 'obvrski',
                key: 'obvrski',
                display: 'Обврски'
            },
            {
                id: 7,
                data: null,
                range: { from: null, to: null },
                showCurrency: true,
                selectedList: [],
                type: 'range',
                name: 'activa',
                key: 'activa',
                display: 'Актива'
            },

        ],

        hasFilters: false

    },

    mutations: {

        setHasFilters( state, filters ) { state.hasFilters = filters },
        setRegionsData( state, data ) { state.filters[0].data = data },
        setIndustriesData( state, data ) { state.filters[1].data = data },
        setIndustriesGroupsData( state, data ) { state.filters[1].groupsData = data }

    },

    actions: {

        async getFilters({ dispatch, commit }) {

            const filters = await dispatch('fetch', {

                url: 'api/enums',
                withCredentials: true

            })

            if ( !filters.errors ) {

                if ( filters.payload.regions ) { // Transform enums arrays

                    const data = []
                    const rawData = filters.payload.regions

                    rawData.forEach(( region, index ) => {

                        const tempList = []

                        region.forEach( state => {

                            tempList.push({
                                name:  state.Naziv,
                                region: state.Region,
                                selected: false
                            })

                        })

                        data.push({
                            id: index,
                            selected: false,
                            name: region[0].Region,
                            states: tempList
                        })

                    })

                    commit( 'setRegionsData', data )

                }

                if ( filters.payload.industries ) {

                    const data = []
                    const rawData = filters.payload.industries

                    rawData.forEach( industry => {

                        data.push({

                            id: industry.Sifra,
                            name: industry.Naziv

                        })

                    })

                    commit( 'setIndustriesData', data )

                }

                if ( filters.payload.industryGroups ) {

                    const data = []
                    const rawData = filters.payload.industryGroups

                    rawData.forEach( group => {

                        const currentGroup = []

                        group.forEach( industry => {

                            const currentIndustry = {
                                id: industry.Sifra,
                                name: industry.Naziv,
                                group: industry.Grupa,
                                sector: industry.Sektor,
                                selected: false
                            }

                            currentGroup.push(currentIndustry)

                        })

                        data.push(currentGroup)

                    })

                    commit( 'setIndustriesGroupsData', data )

                }

            }

            return filters

        },

        removeFilters({state, commit}) {

            const dirtyFilters = state.filters

            dirtyFilters.forEach( filter => {

                if ( filter.type === 'table' ) {

                    filter.data.forEach( item => {

                        item.selected = false

                        item.states.forEach( state => state.selected = false )

                    })

                }

                if ( filter.type === 'select' ) {

                    filter.groupsData.forEach( group => {

                        group.forEach( industry => industry.selected = false )

                    })

                }

                if ( filter.type === 'range' ) {

                    filter.range.to = null
                    filter.range.from = null

                }

            })

            commit( 'setHasFilters', false )
            commit( 'setCompanies', [] )
            commit( 'setCount', 0 )

            setTimeout(() => {

                window.scrollTop = 0

                commit( 'setBanner', false )

            }, 1000)

        }

    }

}