<template>
    
    <div class="bg-gray-100" >

        <!-- Details banner -->

        <div
        
        style="background-image: url('/images/details.jpg')"
        class="w-full h-20 z-10 bg-center bg-no-repeat bg-cover sticky top-0 transition-all duration-200"
        
        :class="{
            'top-20': header 
        }"

        >

            <div class="w-full h-full px-4 md:px-10 bg-i-blue bg-opacity-75 flex items-center justify-center" >

                <div class="overflow-hidden" >

                    <slider :nav="false" id="5" >

                        <template v-slot:slider >

                            <div class="flex flex-col items-start" :key="0" >

                                <h1 class="text-base md:text-xl text-white whitespace-no-wrap" >{{ details?.nazivSubjekt || '' }}</h1>

                            </div>

                        </template>

                    </slider>

                    <p class="text-xs md:text-sm text-white text-center" >{{ details?.adresa }}</p>

                </div>

            </div>

        </div>

        <!-- Details nav:web -->

        <div 

        class="hidden max-w-screen-xl px-4 mx-auto py-10 md:flex items-center space-x-4 sticky top-20 z-10 bg-gray-100  transition-all duration-200" 
        
        :class="{
            'top-40': header 
        }"

        >

            <div class="flex items-center space-x-4 px-4 cursor-pointer" @click="$router.go(-1)" >

                <img src="/images/icons/arrow-left-blue.svg" alt="arrow-back" >

                <p class="text-sm text-gray-700" >Назад</p>

            </div>

            <div class="flex-grow flex space-x-8" >

                <button class="nav-item" :class="{ 'nav-item-active': nav === 1 }" @click.prevent="nav = 1" >Скратен Исказ</button>

                <button v-if="isAdmin" class="nav-item" :class="{ 'nav-item-active': nav === 2 }" @click.prevent="nav = 2" >Завршна Сметка</button>

                <button v-if="isAdmin" class="nav-item opacity-50" >Кредити</button>

            </div>

            <div>

                <button class="nav-item-full" @click.prevent="exportCompanyDetails" >
                    
                    Експортирај

                    <img draggable="false" class="ml-4" src="/images/icons/export.svg" alt="export" >
                    
                </button>

            </div>

        </div>

        <!-- Details nav:mobile -->

        <div 
        
        class="w-full max-w-full md:hidden transition-all duration-200 sticky top-20 bg-white z-10 border-solid border-gray-200" 

        :class="{ 
            'top-40': header
        }"
        
        >

            <div class="grid grid-cols-filter items-center justify-center py-4 md:py-10 md:px-10 md:pr-0" >

                <div class="flex items-center px-4" >

                    <i-select 

                    class="min-w-20"
                    
                    :showBadge="false"
                    :showLabel="false"

                    :list="documentYears"

                    v-model="documentYear"

                    v-if="company.tekovnaGodina"
                    
                    />

                </div>

                <div class="overflow-hidden" >

                    <slider :nav="false" >

                        <template v-slot:slider >

                            <button class="nav-item" :key="0" :class="{ 'nav-item-active': nav === 1 }" @click.prevent="nav = 1" >Скратен Исказ</button>

                            <button class="nav-item" :key="1" :class="{ 'nav-item-active': nav === 2 }" @click.prevent="nav = 2" >Завршна Сметка</button>

                            <button class="nav-item opacity-50" :key="2" >Кредити</button>

                        </template>

                    </slider>

                </div>

            </div>

        </div>

        <!-- Detais Table:web -->

        <div class="w-full max-w-screen-xl h-16 md:h-10 m-auto text-sm text-gray-600 flex space-x-4 items-center px-4" >

            <p>ЕДБ: <span class="text-i-blue" >{{ details?.edb }}</span></p>

            <p>ЕМБС: <span class="text-i-blue" >{{ details?.embs }}</span></p>

        </div>

        <div v-if="loader" class="p-20 flex space-x-4 items-center justify-center" >

            <img src="/images/loader.svg" alt="loader" >

            <p>Податоците се вчитуваат...</p>

        </div>

        <div v-if="!loader" >

            <transition name="route-in" mode="out-in" >

                <!-- Table Short -->

                <component :is="`details${nav}`" :short="short" :final="final" :year="documentYear" />

            </transition>
            
        </div>

    </div>

</template>

<script>

    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    import { ref, computed, onBeforeMount } from 'vue'

    import { cleanTitles } from '@/assets/scripts/formatTitle'
    import { downloadFile } from '@/assets/scripts/downloadFile'

    import slider from '@/components/Slider'
    import iSelect from '@/components/select'
    import details1 from '@/components/details/skraten'
    import details2 from '@/components/details/zavrshna'

    export default {
    
        name: 'Company Details',

        components: { iSelect, slider, details1, details2 },

        setup() {

            const store = useStore()
            const route = useRouter().currentRoute

            const header = computed({
                get: () => store.state.header,
                set: value => store.commit( 'setHeader', value )
            })

            // Company Details

            const company = ref({})
            const loader = ref(false)

            // const documentYears = [

            //     { 
            //         name: `${ new Date().getFullYear() - 1}`,
            //         key: 'tekovnaGodina',
            //         id: 1 
            //     }, 
            //     { 
            //         name: `${ new Date().getFullYear() - 2 }`,
            //         key: 'prethodna',
            //         id: 2 
            //     }

            // ]

            const documentYear = ref([{
                name: `2019`,
                key: 'tekovnaGodina',
                id: 1 
            }])

            const isAdmin = computed( () => store.state.role === 'admin' )

            const short = computed(() => company.value?.skraten )
            const details = computed(() => company.value?.detali )
            const final = computed(() => { return { uspeh: company.value?.bilansUspeh, sostojba: company.value?.bilansSostojba } })

            const documentYears = computed(() => [
                {
                    name: `${company.value.tekovnaGodina}`,
                    key: 'tekovnaGodina',
                    id: 1 
                },
                {
                    name: `${company.value.tekovnaGodina - 1}`,
                    key: 'prethodna',
                    id: 2 
                }
            ])

            const getCompanyDetails = async () => {

                loader.value = true

                const id = route.value.params.id

                const details = await store.dispatch( 'getCompanyDetails', id )

                if ( !details.errors ) {

                    const rawData = details.payload

                    const stateBalance = rawData?.bilansSostojba
                    const successBalance = rawData?.bilansUspeh

                    cleanTitles(stateBalance)
                    cleanTitles(successBalance)

                }

                company.value = details.payload
                loader.value = false

                documentYear.value = [{
                    name: `${company.value.tekovnaGodina}`,
                    key: 'tekovnaGodina',
                    id: 1 
                }]

            }

            // Export 

            const exportCompanyDetails = async () => {

                const id = route.value.params.id

                const exportDetails = await store.dispatch( 'exportCompanyDetails', { id: id, type: nav.value } )

                if ( !exportDetails.errors ) downloadFile( exportDetails.payload, 'export' )

            }

            onBeforeMount( async () => {

                await getCompanyDetails()

            })

            // Navigation

            const nav = ref(1)

            return {
                nav,
                short,
                final,
                header,
                loader,
                isAdmin,
                details,
                company,
                documentYear,
                documentYears,
                exportCompanyDetails
            }

        }

    }

</script>

<style scoped>

    .nav-item {
        @apply w-auto whitespace-no-wrap text-i-blue text-sm p-2 border border-solid border-i-blue rounded;
    }

    .nav-item-active {
        @apply bg-i-blue text-white;
    }

    .nav-item-full {
        @apply w-auto whitespace-no-wrap text-white text-sm flex items-center p-4 bg-i-red rounded;
    }

@media (min-width: 768px) {

    .nav-item {
        @apply w-auto whitespace-no-wrap text-i-blue text-sm p-4 border border-solid border-i-blue rounded;
    }

    .nav-item-active {
        @apply bg-i-blue text-white;
    }

    .nav-item-full {
        @apply w-auto whitespace-no-wrap text-white text-sm flex items-center p-4 bg-i-red rounded;
    }

}

</style>