import store from '@/store/index'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Login from '@/views/Login.vue'
import Details from '@/views/Details.vue'
import Contact from '@/views/Contact.vue'
import Profile from '@/views/Profile.vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import { isExpired } from '@/assets/scripts/timeDifference'
import { createRouter, createWebHistory } from 'vue-router'

const routeGuard = async (next) => {

  if ( store.state.auth ) next()

  else {

    try {

      const auth = await new createAuth0Client({

        domain: 'intela.eu.auth0.com',
        client_id: 'emQm5k4Q00zgsEnelJhaVc88DuYsIkki',
        redirect_uri: window.location.origin,
        cacheLocation: 'localstorage',
        useRefreshTokens: true
  
      })

      const isAuth = await auth.isAuthenticated()

      if ( isAuth ) {

        const claims = await auth.getIdTokenClaims()

        // Check if token is expired
        // oauth token is in seconds

        const exp = claims.exp
        const cur = new Date().getTime()

        if ( isExpired( cur, exp ) ) {

          auth.logout({ returnTo: window.location.origin })
          localStorage.clear()
          next()

        }

        else {

          const roles = claims['https://intela.mk/roles']
          const role = roles.length > 0 ? roles[0] : 'guest'

          store.commit( 'setRole', role )
          store.commit( 'setAuth', claims.__raw )
          store.commit( 'setUser', claims.name )
          store.commit( 'setUserEmail', claims.email )

          next()

        }

      }

      else {

        const query = window.location.search

        if ( query.includes("code=") && query.includes("state=") ) {

          await auth.handleRedirectCallback()

          next({ name: 'Home' })

        }

        else await auth.loginWithRedirect() 

      }

    }

    catch(ex) {
      console.log(ex)
      localStorage.clear()
      window.location.reload()
    }
  
  }

}

const routes = [

  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/login',
    name: 'Login',
    component: Login
  },

  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },

  {
    path: '/:id',
    name: 'Details',
    component: Details
  },

  {
    path: '/about',
    name: 'About',
    component: About
  },

  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }


]

const router = createRouter({

  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },

})

router.beforeEach((to, from, next) => routeGuard(next))

export default router
