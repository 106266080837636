<template>

    <!-- Nested Drop List -->

    <div class="relative" :id="`droplist-${id}`" v-out="() => openList = false" >

        <!-- Nested Drop List Head -->

        <div
        
        class="w-full h-14 bg-white rounded-md flex select-none cursor-pointer" 
        
        :id="`droplist-${id}-head`" 

        @click="openList = !openList"
        
        >

            <!-- Select Head value -->

            <div class="h-full flex flex-grow items-center px-4 overflow-hidden" >

                <p class="w-full text-sm whitespace-no-wrap truncate text-i-blue font-medium" v-if="headValue" >{{ headValue }}</p>

                <p class="text-sm text-gray-500" v-else >{{ placeholder }}</p>

            </div>

            <!-- Select Head Icon -->

            <div 
            
            class="h-full px-4 flex items-center transform transition-transform duration-200" 
            
            :class="{ 'rotate-180': openList }"

            >

                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.66">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0775 6.07764L17.256 7.25615L10.0001 14.5121L2.74414 7.25615L3.92265 6.07764L10.0001 12.155L16.0775 6.07764Z" fill="#7A809C"/>
                    </g>
                </svg>

            </div>

        </div>

        <!-- Nested Drop List Body -->

        <div
        
        class="w-full h-auto max-h-0 absolute z-20 bg-white shadow-lg rounded-md rounded-t-none border-t border-solid border-gray-300 overflow-hidden overflow-y-auto" 
        
        :id="`droplist-${id}-body`"
        :class="{
            'max-h-64': openList
        }"

        >

            <!-- Select Body Search -->

            <div class="w-full h-11 flex items-center sticky top-0 z-10 bg-white border-b border-solid border-gray-300" >

                <input 
                
                type="text" 
                class="text-sm outline-none border-0 rounded-none bg-transparent" 

                :placeholder="searchPlaceholder"
                
                v-if="showSearch"

                v-model="search" 
                
                >

                <div class="w-14 h-14 flex items-center justify-center" >

                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16671 15.5002C4.11662 15.5002 0.833374 12.2169 0.833374 8.16683C0.833374 4.11674 4.11662 0.833496 8.16671 0.833496C12.2168 0.833496 15.5 4.11674 15.5 8.16683C15.5 9.86148 14.9252 11.4219 13.9599 12.6637L18.8982 17.602L17.6019 18.8983L12.6635 13.96C11.4217 14.9253 9.86136 15.5002 8.16671 15.5002ZM13.6667 8.16681C13.6667 11.2044 11.2043 13.6668 8.1667 13.6668C5.12914 13.6668 2.6667 11.2044 2.6667 8.16681C2.6667 5.12925 5.12914 2.66681 8.1667 2.66681C11.2043 2.66681 13.6667 5.12925 13.6667 8.16681Z" fill="lightgray"/>
                    </svg>

                </div>

            </div>

            <!-- Group / Sector -->

            <div 

            class="relative"

            :key="index"

            v-for="(group, index) in data" 

            v-show="search ? group.filter( g => g.name.match(new RegExp( search, 'i' )) || g.id.match(new RegExp( search, 'i' )) ).length > 0 || group[0].sector.match(new RegExp( search, 'i' )) : true"

            >

                <!-- Group Head -->

                <div 
                
                class="w-full h-11 sticky top-11 px-2 flex space-x-4 items-center text-sm text-gray-800 bg-gray-200 hover:bg-gray-300 border-b border-gray-300 border-solid cursor-pointer" 

                @click="() => {

                    if ( group.map( e => e.selected ).every( v => v === true ) ) {

                        group.forEach( entry => entry.selected = false )

                    }

                    else group.forEach( entry => entry.selected = true )

                }"
                
                >

                    <div class="filter-icon w-6 h-full flex items-center justify-center" >

                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="group.map( e => e.selected ).every( v => v === true )" >
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.29289L10 13.5858L7.70711 11.2929L6.29289 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.29289Z" fill="#4C6DDF"/>
                        </svg>

                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else >
                            <g opacity="0.66">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM17 11H13V7H11V11H7V13H11V17H13V13H17V11Z" fill="#7A809C"/>
                            </g>
                        </svg>

                    </div>

                    <p class="text-i-blue font-medium whitespace-no-wrap" >{{ group[0].group }}</p>

                    <p class="truncate" >{{ group[0].sector }}</p>

                </div>

                <!-- Group Items -->

                <div 
                
                class="w-full h-11 px-3 flex space-x-4 items-center text-sm text-gray-600 hover:bg-gray-100 border-b border-gray-300 border-solid cursor-pointer select-none" 
                
                :key="industry.id"

                v-for="industry in group.filter( g => g.name.match(new RegExp( search, 'i' )) || g.id.match(new RegExp( search, 'i' )))"

                v-show="!group.map( e => e.selected ).every( v => v === true )"

                @click="industry.selected = !industry.selected"

                >

                    <div class="filter-icon" >

                        <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="industry.selected" >
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.29289L10 13.5858L7.70711 11.2929L6.29289 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.29289Z" fill="#4C6DDF"/>
                        </svg>

                        <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else >
                            <g opacity="0.66">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="#7A809C"/>
                            </g>
                        </svg>

                    </div>

                    <p class="text-i-blue text-opacity-75" >{{ industry.id }}</p>

                    <p class="truncate" :class="{ 'text-i-blue': industry.selected }" >{{ industry.name }}</p>

                </div>

            </div>

        </div>

    </div>

</template>

<script>

    import { ref, computed } from 'vue'
    import { out } from '@/assets/scripts/clickOutside'

    export default {
    
        name: 'Nested Drop List',

        props: {

            id: {
                type: [ Number, String ],
                default: 0
            },

            placeholder: { 
                type: String, 
                default: 'Select Item' 
            },

            searchPlaceholder: {
                type: String, 
                default: 'Search Item' 
            },

            searchable: {
                type: Boolean,
                default: true
            },

            data: Array,
            multiple: Boolean,
            modelValue: [ Array, String, Number ]
        },

        setup(props) {

            // LIST

            const openList = ref(false)

            const search = ref('') // Search query

            const showSearch = computed( () => props.searchable && props.data.length > 10 )
            
            const headValue = computed( () => {

                const selectedGroups = []

                props.data.forEach( group => {

                    const isSelected = group.map( g => g.selected ).every( v => v === true ) 

                    if ( isSelected ) selectedGroups.push(group[0].sector)

                    else {

                        group.forEach( industry => {

                            industry.selected ? selectedGroups.push(industry.name) : false

                        })

                    }

                })

                return selectedGroups.length > 0 ? selectedGroups.join(', ') : null

            })

            return {
                search,
                openList,
                headValue,
                showSearch
            }

        },

        directives: { out }

    }

</script>