<template>
    
    <div id="app" >

        <i-header />

        <router-view v-slot="{ Component }" >

            <transition name="route-in" mode="out-in" >

                <component :is="Component" />

            </transition>

        </router-view>

        <div id="portal" class="w-full h-auto fixed top-0 z-50 bg-gray-200" >



        </div>

        <!-- <div class="w-full h-20 bg-gray-100" ></div> -->

    </div>

</template>

<script>

    import { useStore } from 'vuex'
    import { watch, computed } from 'vue'

    import './assets/css/main.css'
    import iHeader from '@/components/Header.vue'

    export default {
    
        name: 'App',

        components: { iHeader },

        setup() {

            const store = useStore()

            const auth = computed(() => store.state.auth )

            watch( auth, async (n) => {

                if ( n ) await store.dispatch('getFilters')

            }, { immediate: true })

            return {
                auth
            }

        }
    }

</script>