<template>

    <div class="min-h-web-app" >

        <!-- Banner -->

        <div 
        
        id="banner"
        style="background-image: url('/images/home.jpg')"
        class="w-full h-20 z-10 bg-center bg-no-repeat bg-cover sticky top-0 transition-all duration-200"

        :class="{ 
            'top-20': header,
            'md:h-84': !collapseBanner 
        }"
        
        >

            <div class="w-full h-full px-4 md:px-10 bg-i-blue bg-opacity-50 flex items-center justify-center" >

                <div class="w-full max-w-4xl flex items-center bg-white rounded-lg px-4" >

                    <img src="/images/icons/search.svg" >

                    <p class="hidden md:block whitespace-no-wrap pl-4" >Најди Компанија</p>

                    <input 

                    type="text" 
                    class="border-0 text-sm text-gray-700" 
                    placeholder="Име, ЕДБ или ЕМБС" 
                    
                    v-model="query" 

                    @input="search"
                    
                    >

                </div>

            </div>

        </div>

        <!-- Filters -->

        <div 
        
        class="w-full max-w-full transition-all duration-200 sticky top-20 bg-white z-10 border-solid border-gray-200" 

        :class="{ 
            'top-40': header,
            'md:top-84': !header && !collapseBanner,
            'md:top-92': header && !collapseBanner,
            'border-b-2': collapseBanner 
        }"
        
        >

            <div class="grid grid-cols-filter items-center justify-center py-4 md:py-10 md:px-10 md:pr-0" >

                <div class="flex items-center px-4" >

                    <p class="text-xs md:text-sm" >Филтрирај:</p>

                </div>

                <div class="overflow-hidden" >

                    <slider :nav="false" >

                        <template v-slot:slider >

                            <div 

                            class="w-auto whitespace-no-wrap py-2 px-4 md:py-4 md:px-10 border border-solid border-i-blue rounded cursor-pointer"
                            
                            :key="filter.id"
                            :class="{ 'bg-i-blue': filter.id === selectedFilter?.id }"
                            
                            v-for="filter in filters"

                            @click="selectedFilter === filter ? selectedFilter = null : selectedFilter = filter"
                            
                            >

                                <p 
                                
                                class="text-xs md:text-sm text-white" 

                                :class="{ 'text-i-blue': filter.id != selectedFilter?.id }"
                                
                                >{{ filter.display }}</p>

                            </div>

                        </template>

                    </slider>

                </div>

            </div>

            <filters-bar 
                
            :filter="selectedFilter" 

            :to="selectedFilter ? selectedFilter?.range?.to : null"
            :from="selectedFilter ? selectedFilter?.range?.from : null"
            :selected="selectedFilter ? selectedFilter?.selectedList : []"

            @update:from="selectedFilter ? selectedFilter.range.from = $event : false"
            @update:to="selectedFilter ? selectedFilter.range.to = $event : false"
            @update:selected="selectedFilter ? selectedFilter.selectedList = $event : false"

            @close="selectedFilter = null"
            
            />

        </div>

        <!-- Content -->

        <div class="max-w-screen-xl mx-auto md:p-10" >

            <div class="flex items-center justify-between px-4" >

                <h2 class="text-lg md:text-xl py-6 md:py-10" >Компаниски Директориум</h2>

                <h2 class="text-i-blue text-sm md:text-base" >{{ companies.length > 0 ? companies.length + ' /' : '' }} {{ count }} <span class="hidden md:inline-block" >{{ count === 1 ? 'Резултат' : 'Резултати' }}</span></h2>

            </div>

            <div class="py-4" >

                <!-- Selected Filters -->

                <transition name="flip" >

                    <div class="h-full flex items-center space-x-4 p-6 bg-gray-100 mb-10" v-if="hasFilters" >

                        <div class="w-full h-auto clearfix" >

                            <p class="text-gray-600 ml-4 py-2" >Додадени Филтри:</p>

                            <div class="tag" @click="removeFilters" >

                                    <p>Одстрани Филтри</p>

                            </div>

                            <div :key="filter.id" v-for="filter in filters" >

                                <div v-if="filter.type === 'table'" >

                                    <div :key="item.id" v-for="item in filter.data.filter( i => i.selected )" class="tag" @click="item.selected = false" >

                                        <p>{{ formatTitle(item.name) }}</p>

                                    </div>

                                    <div :key="item.id" v-show="!item.selected" v-for="item in filter.data.filter( i => !i.selected )" >

                                        <div :key="state.name" v-for="state in item.states.filter( s => s.selected )" class="tag" @click="state.selected = false" >

                                            <p>{{ formatTitle(state.name) }}</p>

                                        </div>

                                    </div>

                                </div>

                                <div v-if="filter.type === 'select'" >

                                    <div 
                                    
                                    class="tag" 
                                    
                                    :key="index" 
                                    
                                    v-for="(group, index) in filter.groupsData.filter( g => g.map( i => i.selected ).every( v => v === true ) )" 

                                    @click="() => group.forEach( industry => industry.selected = false )"
                                    
                                    >

                                        <p>{{ group[0].sector }}</p>

                                    </div>

                                    <div :key="index" v-for="(group, index) in filter.groupsData.filter( g => !g.map( i => i.selected ).every( v => v === true ) )" >

                                        <div class="tag" :key="industry.id" v-for="industry in group.filter( i => i.selected )" @click="industry.selected = false" >

                                            <p>{{ industry.name }}</p>

                                        </div>

                                    </div>

                                </div>

                                <div v-if="filter.type === 'range' && filter.range.from > 0 || filter.range.to > 0" class="tag" @click="[filter.range.from = null, filter.range.to = null]" >

                                    <p>{{ formatTitle(filter.display) }} {{ filter.range.from > 0 ? filter.showCurrency ? 'од: ' + formater.format(filter.range.from) : 'од: ' + filter.range.from : '' }} {{ filter.range.to > 0 ? filter.showCurrency ? 'до: ' + formater.format(filter.range.to) : 'до: ' + filter.range.to : '' }}</p>

                                </div>

                            </div>

                        </div>

                        <div class="h-full hidden md:flex items-center" >

                            <button class="text-white w-content p-4 rounded-md bg-i-red flex space-x-4" @click.prevent="exportFilters" >
                                
                                <p>Експортирај</p>

                                <img src="/images/icons/export.svg" alt="export" v-if="!exportLoader" >

                                <img class="w-6 h-6" src="/images/loader-light.svg" alt="export" v-else >
                                
                            </button>

                        </div>

                    </div>

                </transition>

                <transition name="route-in" mode="out-in" >

                    <div v-if="loader" class="p-20 flex space-x-4 items-center justify-center" >

                        <img src="/images/loader.svg" alt="loader" >

                        <p>Се пребарува...</p>

                    </div>

                    <div v-else-if="( query || hasFilters ) && companies.length === 0 && !loader" class="p-4 md:p-20 flex space-x-4 items-center justify-center" >

                        <p>Нема резултати од вашето пребарување.</p>

                    </div>

                    <div v-else-if="( !query && !hasFilters ) && companies.length === 0 && !loader" class="p-4 md:p-20 flex space-x-4 items-center justify-center" >

                        <p class="text-sm text-center md:text-base text-gray-600" >Внесете клучен збор за пребарување или изберете некој од филтрите</p>

                    </div>

                    <div v-else >

                        <company-card :key="index" :company="company" v-for="( company, index ) in companies" />

                    </div>

                </transition>

            </div>

            <!-- Update Loader -->

            <div class="h-0 overflow-hidden transition-height duration-200" :class="{ 'h-40': companies.length > 0 && pages > 1 && ( blockUpdate || page === pages ) }" >

                <div class="w-full h-20 flex items-center justify-center space-x-4" v-if="page === pages" >

                    <p class="text-gray-600" >Нема повеќе резултати.</p>

                </div>

                <div class="w-full h-20 flex items-center justify-center space-x-4" v-else >

                    <img class="w-8" src="/images/loader.svg" alt="loader" >

                    <p class="text-gray-600" >резултатите се вчитуваат</p>

                </div>

            </div>

        </div>

        <!-- Sticky footer -->

        <div class="w-full h-16 bg-gray-100 sticky bottom-0 border-t border-solid border-gray-300" v-if="showFooter" >

            <div class="w-full h-full max-w-screen-xl m-auto px-10 flex items-center justify-end" >

                <p class="text-sm text-gray-600" >{{ companies.length > 0 ? companies.length + ' /' : '' }} {{ count }} {{ count === 1 ? 'Резултат' : 'Резултати' }}</p>

            </div>
            
        </div>

    </div>
    
</template>

<script>

    import axios from 'axios'
    import { useStore } from 'vuex'
    import { debounce } from '@/assets/scripts/debounce'
    import { formatTitle } from '@/assets/scripts/formatTitle'
    import { sticky, notSticky } from '@/assets/scripts/sticky'
    import { downloadFile } from '@/assets/scripts/downloadFile'

    import { ref, watch, computed, onMounted, onBeforeUnmount } from 'vue'

    import slider from '@/components/Slider.vue'
    import filtersBar from '@/components/Filters.vue'
    import companyCard from '@/components/companyCard.vue'

    export default {
        
        name: 'Home',

        components: { slider, filtersBar, companyCard },

        setup() {

            let source = null
            const CancelToken = axios.CancelToken

            const store = useStore()

            // Header

            const header = computed(() => store.state.header )

            // Companies

            const companies = computed({
                get: () => store.state.companies.companies,
                set: val => store.commit( 'setCompanies', val )
            })

            const page = computed({
                get: () => store.state.companies.page,
                set: value => store.commit( 'setPage', value )
            })

            const pages = computed({
                get: () => store.state.companies.pages,
                set: value => store.commit( 'setPages', value )
            })

            const count = computed({
                get: () => store.state.companies.count,
                set: value => store.commit( 'setCount', value )
            })

            const loader = ref(false)
            const searchError = ref('')

            // Filters

            const selectedFilter = ref(null)

            const filters = computed(() => store.state.filters.filters )

            const selectedFilters = ref({})

            const hasFilters = computed({
                get: () => store.state.filters.hasFilters,
                set: value => store.commit( 'setHasFilters', value )
            })

            watch( filters, debounce( async () => { // Search when filters change

                const rawData = [...filters.value]

                const allFilters = [] // This is used to check if there are any filters selected

                const states = []
                const regions = []
                const industry = []
                const sektor = []

                // Create request body object

                rawData.forEach( filter => {

                    if ( filter.type === "table" ) {

                        filter.data.forEach( region => {

                            if ( region.selected ) {

                                regions.push( region.name )
                                region.states.forEach( state => state.selected = false )

                                allFilters.push(region)

                            }

                            else {

                                region.states.forEach( state => {

                                    if ( state.selected ) {

                                        states.push( state.name )
                                        allFilters.push(state)

                                    }

                                })

                            }

                        })

                    }

                    if ( filter.type === "select" ) {

                        filter.groupsData.forEach( group => {

                            const isSelected = group.map( i => i.selected ).every( v => v === true )

                            if ( isSelected ) {

                                sektor.push( group[0].group )
                                allFilters.push( group[0].group )

                            }

                            group.forEach( entry => {

                                if ( !isSelected ) {

                                    if( entry.selected ) {

                                        industry.push( entry.id )
                                        allFilters.push( entry.id )

                                    }

                                }

                            })

                        })

                    }

                    if ( filter.type === 'range' ) {

                        if ( filter.range.from != null || filter.range.to != null ) {

                            let rangeTo = {}
                            let rangeFrom = {}

                            if ( filter.range.from != null ) {

                                rangeFrom = { from: filter.range.from }

                            }

                            if ( filter.range.to != null ) {

                                rangeTo = { to: filter.range.to }

                            }

                            selectedFilters.value[filter.key] = { ...rangeTo, ...rangeFrom }

                            allFilters.push(filter.key)

                        }

                    }

                })

                states.length > 0 ? selectedFilters.value.state = states : selectedFilters.value.state = []
                regions.length > 0 ? selectedFilters.value.region = regions : selectedFilters.value.region = []
                industry.length > 0 ? selectedFilters.value.industry = industry : selectedFilters.value.industry = []
                sektor.length > 0 ? selectedFilters.value.sektor = sektor : selectedFilters.value.sektor = []

                if ( allFilters.length > 0 ) { // If there are filters selected, send request

                    if ( source?.token ) source.cancel('Request was canceled.')
                    source = CancelToken.source()

                    loader.value = true
                    companies.value = []

                    query.value = null

                    const results = await store.dispatch('search', { params: { ...selectedFilters.value }, cancelToken: source?.token })

                    loader.value = false

                    if ( results.errors ) searchError.value = results.errors.map( e => e.value ).join(', ')

                    else {

                        pages.value = results.payload.pages
                        count.value = results.payload.count
                        companies.value = results.payload.items

                    }

                    hasFilters.value = true

                }

                else if ( !query.value ) {

                    page.value = 1
                    pages.value = 0
                    count.value = 0
                    companies.value = []
                    hasFilters.value = false

                }

                else { 

                    hasFilters.value = false 

                }

                page.value = 1

            }, 500 ), { deep: true })

            const removeFilters = () => store.dispatch( 'removeFilters' )

            const closeFiltersOnScroll = () => {

                if ( collapseBanner.value && window.innerWidth > 768 ) {

                    selectedFilter.value = null

                }

            }

            // Export Filtered Results

            const exportLoader = ref(false)

            const exportFilters = async () => {

                exportLoader.value = true

                const filtersExport = await store.dispatch('exportSearch', { params: { search: query.value, page: page.value, ...selectedFilters.value }})

                if ( !filtersExport.errors ) downloadFile( filtersExport.payload, 'export' )

                exportLoader.value = false

            }

            // Update results

            const updateResults = async () => {

                const results = await store.dispatch('search', { params: { search: query.value, page: page.value, ...selectedFilters.value }})

                if ( results.errors ) searchError.value = results.errors.map( e => e.value ).join(', ')
                    
                else {

                    pages.value = results.payload.pages
                    count.value = results.payload.count
                    companies.value = [ ...companies.value, ...results.payload.items ]

                }

            }

            // Banner && update on scroll

            const showFooter = ref(false)
            const blockUpdate = ref(false)
            const collapseBanner = computed({
                get: () => store.state.banner,
                set: value => store.commit( 'setBanner', value )
            })

            const setScrollProperties = async () => {

                const documentHeight = document.documentElement.scrollHeight
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop

                if ( scrollTop > 0 ) collapseBanner.value = true

                if ( ( window.innerHeight + window.pageYOffset >= documentHeight ) && !blockUpdate.value && ( page.value < pages.value ) ) {

                    page.value += 1
                    blockUpdate.value = true

                    await updateResults()

                    blockUpdate.value = false

                }

                showFooter.value = scrollTop > 200

            }

            onMounted(() => {

                window.addEventListener( 'scroll', setScrollProperties )
                window.addEventListener( 'scroll', closeFiltersOnScroll )

            })

            onBeforeUnmount(() => {

                window.removeEventListener( 'scroll', setScrollProperties )
                window.removeEventListener( 'scroll', closeFiltersOnScroll )

            })

            // Search

            const query = computed({
                get: () => store.state.companies.query,
                set: val => store.commit( 'setQuery', val )
            })

            const search = debounce( async() => {

                if ( query.value && query.value.length >= 2 ) {

                    removeFilters()

                    loader.value = true
                    companies.value = []

                    const results = await store.dispatch('search', { params: { search: query.value, page: page.value }, cancelToken: source?.token })

                    loader.value = false
                    
                    if ( results.errors ) searchError.value = results.errors.map( e => e.value ).join(', ')
                    
                    else {

                        pages.value = results.payload.pages
                        count.value = results.payload.count
                        companies.value = results.payload.items

                    }
                    
                }

            }, 500)

            // format numbers to currency

            const formater = new Intl.NumberFormat('mk-MK')

            return {
                page,
                pages,
                count,
                query,
                header,
                search,
                loader,
                filters,
                formater,
                companies,
                showFooter,
                hasFilters,
                blockUpdate,
                searchError,
                formatTitle,
                exportLoader,
                exportFilters,
                removeFilters,
                selectedFilter,
                collapseBanner,
                selectedFilters,
            }

        },

        directives: { sticky, notSticky }

    }

</script>

<style scoped >

    .flip-enter-active, .flip-leave-active {

        transition: 0.3s ease;
    }

    .flip-enter-from {

        transform: rotateX(90deg);
        opacity: 0;

    }

    .flip-leave-to {

        transform: rotateX(-90deg);
        opacity: 0;

    }

    .tag {
        @apply relative flex items-center py-2 px-4 pr-8 border border-solid border-i-blue text-sm text-i-blue rounded-md float-left ml-2 mt-2 select-none cursor-pointer;
    }

    .tag p {
        @apply relative;
    }

    .tag p::after {
        content: url('/images/icons/close-small.svg');
        @apply absolute;
    }

</style>
