<template>
    
    <div class="w-full h-screen grid grid-cols-2" >

        <div class="bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg.png')" ></div>

        <div class="bg-white grid content-center" >

            <div class="flex flex-col space-y-2 items-center justify-center py-20" >

                <img src="/images/logo/frame.svg" alt="logo" >

                <h1 class="text-2xl font-bold tracking-widest" >ИНТЕЛА</h1>

            </div>

            <p class="text-xl text-center" >Лесно достапни <br/> компаниски информации</p>

            <form class="w-full max-w-lg mx-auto p-10 grid gap-4" >

                <input 
                
                type="text" 
                placeholder="Емаил Адреса"

                v-model="login.email"

                >

                <input 
                
                type="password"  
                placeholder="Лозинка"

                v-model="login.password"

                >

                <p class="text-xs text-red-600 text-center" >{{message}}</p>

                <button :disabled="!login.email || !login.password" class="btn" @click.prevent="authenthicate" >Најави Се</button>

                <p class="text-xs text-gray-500 text-center" >Заборавена лозинка?</p>

            </form>

        </div>

    </div>

</template>

<script>

    import { ref, watch, reactive } from 'vue'
    import { useRouter } from 'vue-router'

    export default {
    
        name: 'Login',

        setup() {
            
            const router = useRouter()

            const message = ref('')

            const login = reactive({

                email: 'aleksandar@intela.mk',
                password: 'test1234',

            })

            const authenthicate = () => {

                if ( login.email === 'aleksandar@intela.mk' && login.password === 'test1234' ) {

                    localStorage.setItem( 'token', true )
                    router.push('/')

                }

                else {

                    localStorage.removeItem('token')
                    message.value = 'Невалидни информации.'

                }

            }

            watch( login, () => {

                message.value = ''

            })

            return {
                login,
                message,
                authenthicate
            }

        }

    }

</script>