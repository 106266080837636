export const sticky = {

    mounted(el, binding) {

        const key = Object.keys( binding.modifiers )[0] || 'top'
        const offset = binding.arg ? binding.arg + 'px' : '0px'
        const offsetValue = binding.arg ? parseInt(binding.arg) : 0

        el.style[key] = offset
        el.style.position = "sticky"

        document.addEventListener( 'scroll', function() {

            const elementOffsetTop = el.offsetTop
            const elementOffsetHeight = el.offsetHeight
            const scrollTop = document.getElementsByTagName("html")[0].scrollTop
            const distance = elementOffsetTop - scrollTop

            if ( key === 'bottom' ) {

                if ( window.innerHeight > (elementOffsetTop - scrollTop) + (elementOffsetHeight + offsetValue) ) {

                    el.setAttribute( 'sticky', false )

                }

                else {

                    el.setAttribute( 'sticky', true )
                    binding.value ? binding.value() : false

                }

            }  

            else {

                if ( distance > offsetValue ) {

                    el.setAttribute( 'sticky', false )

                }
    
                else {
    
                    el.setAttribute( 'sticky', true )
                    binding.value ? binding.value() : false
    
                }

            }

        })

    }

}

export const notSticky = {

    mounted(el, binding) {

        const key = Object.keys( binding.modifiers )[0] || 'top'
        const offset = binding.arg ? binding.arg + 'px' : '0px'
        const offsetValue = binding.arg ? parseInt(binding.arg) : 0

        el.style[key] = offset
        el.style.position = "sticky"

        document.addEventListener( 'scroll', function() {

            const elementOffsetTop = el.offsetTop
            const elementOffsetHeight = el.offsetHeight
            const scrollTop = document.getElementsByTagName("html")[0].scrollTop
            const distance = elementOffsetTop - scrollTop

            if ( key === 'bottom' ) {

                if ( window.innerHeight > (elementOffsetTop - scrollTop) + (elementOffsetHeight + offsetValue) ) {

                    el.setAttribute( 'sticky', false )
                    binding.value ? binding.value() : false

                }

                else {

                    el.setAttribute( 'sticky', true )

                }

            }  

            else {

                if ( distance > offsetValue ) {

                    el.setAttribute( 'sticky', false )
                    binding.value ? binding.value() : false
    
                }
    
                else {
    
                    el.setAttribute( 'sticky', true )
    
                }

            }

        })

    }

}