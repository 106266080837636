<template>
    
    <div class="max-w-screen-xl mx-auto pb-10 md:py-10" >

        <!-- Table Section -->
        
        <div class="px-2 md:px-4 mt-4" >

            <!-- Table Head -->

            <div class="w-full h-12 text-xs md:text-sm grid grid-cols-14 items-center px-2 border-b border-solid border-gray-300" >

                <h2 class="text-sm md:text-base text-i-blue font-bold col-span-10 md:col-span-8" >БИЛАНС НА УСПЕХ</h2>

                <p class="text-right col-span-2 hidden md:block" >{{ year ? year[0].name : null }}</p>

                <p class="text-right col-span-2 hidden md:block" >{{ year ? parseInt(year[0].name) - 1  : null }}</p>

                <p class="text-right col-span-4 md:col-span-2" >Промена во %</p>

            </div>

            <!-- Table Row -->

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Приходи од работењето</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.prihodi ? short?.prihodi[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.prihodi?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.prihodi?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.prihodi?.promenaProcent >= 0,
                    'text-i-red': short?.prihodi?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.prihodi?.promenaProcent > 0 ? '+' : '' }} {{ short?.prihodi?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Расходи од работењето</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.rashodi ? short?.rashodi[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.rashodi?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.rashodi?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.rashodi?.promenaProcent >= 0,
                    'text-i-red': short?.rashodi?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.rashodi?.promenaProcent > 0 ? '+' : '' }} {{ short?.rashodi?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>EBITDA</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.ebitda ? short?.ebitda[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.ebitda?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.ebitda?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.ebitda?.promenaProcent >= 0,
                    'text-i-red': short?.ebitda?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.ebitda?.promenaProcent > 0 ? '+' : '' }} {{ short?.ebitda?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row table-row-main" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Нето Добивка / Загуба</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.neto ? short?.neto[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-700 col-span-2 hidden md:block" >{{ formater.format( short?.neto.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-700 col-span-2 hidden md:block" >{{ formater.format( short?.neto.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.neto?.promenaProcent >= 0,
                    'text-i-red': short?.neto?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.neto?.promenaProcent > 0 ? '+' : '' }} {{ short?.neto?.promenaProcent || '0' }} %
                
                </p>

            </div>

        </div>

        <!-- Table Section -->

        <div class="px-2 mt-4" >

            <!-- Table Head -->

            <div class="w-full h-12 text-sm grid grid-cols-14 items-center px-2 border-b border-solid border-gray-300" >

                <h2 class="text-sm md:text-base text-i-blue font-bold col-span-8" >БИЛАНС НА СОСТОЈБА</h2>

            </div>

            <!-- Table Row -->

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Нетековни средства</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.netekovni ? short?.netekovni[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.netekovni?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.netekovni?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.netekovni?.promenaProcent >= 0,
                    'text-i-red': short?.netekovni?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.netekovni?.promenaProcent > 0 ? '+' : '' }} {{ short?.netekovni?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Побарувања од купувачи</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.pobaruvanja ? short?.pobaruvanja[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.pobaruvanja?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.pobaruvanja?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.pobaruvanja?.promenaProcent >= 0,
                    'text-i-red': short?.pobaruvanja?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.pobaruvanja?.promenaProcent > 0 ? '+' : '' }} {{ short?.pobaruvanja?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Останати побарувања</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.ostanatiPobaruvanja ? short?.ostanatiPobaruvanja[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.ostanatiPobaruvanja?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.ostanatiPobaruvanja?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.ostanatiPobaruvanja?.promenaProcent >= 0,
                    'text-i-red': short?.ostanatiPobaruvanja?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.ostanatiPobaruvanja?.promenaProcent > 0 ? '+' : '' }} {{ short?.ostanatiPobaruvanja?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Залихи</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.zalihi ? short?.zalihi[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.zalihi?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.zalihi?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.zalihi?.promenaProcent >= 0,
                    'text-i-red': short?.zalihi?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.zalihi?.promenaProcent > 0 ? '+' : '' }} {{ short?.zalihi?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Пари и парични еквиваленти</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.pari ? short?.pari[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.pari?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.pari?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.pari?.promenaProcent >= 0,
                    'text-i-red': short?.pari?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.pari?.promenaProcent > 0 ? '+' : '' }} {{ short?.pari?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>АВР</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.avr ? short?.avr[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.avr?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.avr?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.avr?.promenaProcent >= 0,
                    'text-i-red': short?.avr?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.avr?.promenaProcent > 0 ? '+' : '' }} {{ short?.avr?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row table-row-main" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Вкупна Актива</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.vkupnaAktiva ? short?.vkupnaAktiva[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-700 col-span-2 hidden md:block" >{{ formater.format( short?.vkupnaAktiva?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-700 col-span-2 hidden md:block" >{{ formater.format( short?.vkupnaAktiva?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.vkupnaAktiva?.promenaProcent >= 0,
                    'text-i-red': short?.vkupnaAktiva?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.vkupnaAktiva?.promenaProcent > 0 ? '+' : '' }} {{ short?.vkupnaAktiva?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Вкупен капитал</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.vkupenKapital ? short?.vkupenKapital[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.vkupenKapital?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.vkupenKapital?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.vkupenKapital?.promenaProcent >= 0,
                    'text-i-red': short?.vkupenKapital?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.vkupenKapital?.promenaProcent > 0 ? '+' : '' }} {{ short?.vkupenKapital?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Обврски кон добавувачи</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.obvrski ? short?.obvrski[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.obvrski?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.obvrski?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.obvrski?.promenaProcent >= 0,
                    'text-i-red': short?.obvrski?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.obvrski?.promenaProcent > 0 ? '+' : '' }} {{ short?.obvrski?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Кредити краткорочни</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.kreditiKratkorichni ? short?.kreditiKratkorichni[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.kreditiKratkorichni?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.kreditiKratkorichni?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.kreditiKratkorichni?.promenaProcent >= 0,
                    'text-i-red': short?.kreditiKratkorichni?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.kreditiKratkorichni?.promenaProcent > 0 ? '+' : '' }} {{ short?.kreditiKratkorichni?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Кредити долгорочни</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.kreditiDolgorochni ? short?.kreditiDolgorochni[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.kreditiDolgorochni?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.kreditiDolgorochni?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.kreditiDolgorochni?.promenaProcent >= 0,
                    'text-i-red': short?.kreditiDolgorochni?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.kreditiDolgorochni?.promenaProcent > 0 ? '+' : '' }} {{ short?.kreditiDolgorochni?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Останати долгорочни обврски</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.dolgorochniObvrski ? short?.dolgorochniObvrski[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.dolgorochniObvrski?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.dolgorochniObvrski?.prethodna ) }}</p>

                <p class="text-right text-i-blue col-span-3 md:col-span-2" >{{ short?.dolgorochniObvrski?.promenaProcent || '0' }} %</p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Останати краткорочни обврски</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.kratkorochniObvrski ? short?.kratkorochniObvrski[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.kratkorochniObvrski?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.kratkorochniObvrski?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.kratkorochniObvrski?.promenaProcent >= 0,
                    'text-i-red': short?.kratkorochniObvrski?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.kratkorochniObvrski?.promenaProcent > 0 ? '+' : '' }} {{ short?.kratkorochniObvrski?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row" >

                <div class="col-span-11 md:col-span-8" >

                    <p>ПВР</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.pvb ? short?.pvb[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.pvb?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-500 col-span-2 hidden md:block" >{{ formater.format( short?.pvb?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.pvb?.promenaProcent >= 0,
                    'text-i-red': short?.pvb?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.pvb?.promenaProcent > 0 ? '+' : '' }} {{ short?.pvb?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row table-row-main" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Вкупна Пасива</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.vkupnaPasiva ? short?.vkupnaPasiva[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-700 col-span-2 hidden md:block" >{{ formater.format( short?.vkupnaPasiva?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-700 col-span-2 hidden md:block" >{{ formater.format( short?.vkupnaPasiva?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.vkupnaPasiva?.promenaProcent >= 0,
                    'text-i-red': short?.vkupnaPasiva?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.vkupnaPasiva?.promenaProcent > 0 ? '+' : '' }} {{ short?.vkupnaPasiva?.promenaProcent || '0' }} %
                
                </p>

            </div>

            <div class="table-row table-row-main" >

                <div class="col-span-11 md:col-span-8" >

                    <p>Број на Вработени</p>

                    <p class="text-gray-500 md:hidden" >{{ formater.format( short?.brojVraboteni ? short?.brojVraboteni[year ? year[0]?.key : null] : null ) }}</p>

                </div>

                <p class="text-right text-gray-700 col-span-2 hidden md:block" >{{ formater.format( short?.brojVraboteni?.tekovnaGodina ) }}</p>

                <p class="text-right text-gray-700 col-span-2 hidden md:block" >{{ formater.format( short?.brojVraboteni?.prethodna ) }}</p>

                <p 
                
                class="text-right col-span-3 md:col-span-2" 
                
                :class="{

                    'text-i-blue': short?.brojVraboteni?.promenaProcent >= 0,
                    'text-i-red': short?.brojVraboteni?.promenaProcent < 0,

                }"
                
                >
                
                {{ short?.brojVraboteni?.promenaProcent > 0 ? '+' : '' }} {{ short?.brojVraboteni?.promenaProcent || '0' }} %
                
                </p>

            </div>

        </div>

    </div>

</template>

<script>

    export default {

        props: {
            short: Object,
            year: Object
        },

        setup() {

            const formater = new Intl.NumberFormat('mk-MK')

            return {
                formater
            }

        }

    }

</script>