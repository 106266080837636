<template>
    
    <div class="max-w-screen-xl mx-auto pb-10 md:py-10" >

        <div class="h-20 flex items-center justify-center mb-10" >

            <button 
            
            class="text-center text-gray-600 text-xs md:text-sm flex-grow px-6 py-4 border-b-2 border-solid border-gray-200" 

            :class="{ 
                'text-i-blue': nav === 1,
                'border-i-blue': nav === 1
            }"
            
            @click="nav = 1" 
            
            >
            
                БИЛАНС НА УСПЕХ
            
            </button>

            <button 
            
            class="text-center text-sm text-gray-600 flex-grow px-6 py-4 border-b-2 border-solid border-gray-200" 

            :class="{ 
                'text-i-blue': nav === 2,
                'border-i-blue': nav === 2
            }"
            
            @click="nav = 2" 
            
            >
            
                БИЛАНС НА СОСТОЈБА
            
            </button>

        </div>

        <transition name="route-in" mode="out-in" >

            <component :is="`sub${nav}`" :uspeh="final.uspeh" :sostojba="final.sostojba" :year="year" />

        </transition>

    </div>

</template>

<script>

    import { ref } from 'vue'

    import sub1 from '@/components/details/zavrshna/uspeh'
    import sub2 from '@/components/details/zavrshna/sostojba'

    export default {
    
        name: 'Zavrshna Smetka',

        props: {

            final: Object,
            year: Object

        },

        components: { sub1, sub2 },

        setup() {

            const nav = ref(1)

            return {
                nav
            }

        }

    }

</script>