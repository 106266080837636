<template>
    
    <div 
    
    v-show="!routes.includes($route.name)" 
    class="w-full h-0 transition-height duration-200 sticky top-0 z-20 px-4 bg-white" 
    
    :class="{ 'h-20': header }"

    >

        <div class="h-full flex items-center justify-between transition-opacity duration-200" :class="{ 'opacity-0': !header }" >

            <div class="h-20 flex items-center space-x-3 p-4 cursor-pointer" @click="resetIntela" >

                <img class="h-12" src="/images/logo/frame.svg" alt="logo" >

                <h1 class="text-lg font-bold tracking-widest" >ИНТЕЛА</h1>

            </div>

            <div class="h-full flex items-center" >

                <div v-out="closeMenu" class="h-full flex space-x-2 items-center px-4 relative cursor-pointer select-none" @click="menu = !menu" >

                    <img src="/images/icons/user.svg" alt="user" >

                    <p class="hidden md:block" >{{ user }}</p>

                    <img class="hidden md:block"  src="/images/icons/arrow-black-small.svg" alt="arrow" >

                    <transition name="route-in" >

                    <div v-if="menu" class="w-content h-auto flex flex-col items-center justify-end p-2 bg-white absolute mt-1 top-80 right-0 rounded-lg shadow-lg cursor-pointer" >

                        <p class="w-full text-sm text-gray-700 text-right whitespace-no-wrap p-2 px-4 hover:bg-gray-200 rounded-md" @click="$router.push('/Profile')" >Профил</p>

                        <p class="w-full text-sm text-gray-700 text-right whitespace-no-wrap p-2 px-4 hover:bg-gray-200 rounded-md" @click="logout" >Одјави Cе</p>

                    </div>

                    </transition>

                </div>

                <div class="hidden md:block h-full p-6" >

                    <img class="h-full object-scale-down" src="/images/logo/usaid.svg" alt="usaid" >

                </div>

            </div>

        </div>

    </div>

</template>

<script>

    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    import { onMounted, ref, inject, computed } from 'vue'
    import { out } from '@/assets/scripts/clickOutside'

    export default {
    
        name: 'Header',

        setup() {

            const store = useStore()
            const router = useRouter()

            const auth = inject('auth')

            const menu = ref(false)

            const closeMenu = () => menu.value = false

            const logout = async () => {

                const auth0 = await auth
                auth0.logout({ returnTo: window.location.origin })
                localStorage.clear()

            }

            // User

            const user = computed(() => store.state.user ? store.state.user.split(' ')[0] : null )

            // Toggle header

            const header = computed({
                get: () => store.state.header,
                set: value => store.commit( 'setHeader', value )
            })

            const lastScrollPosition = ref(0)

            const toggleHeader = () => {

                const screenHeight = screen.height
                const scrollHeight = document.documentElement.scrollHeight
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop

                const scrollSpace = scrollHeight - screenHeight

                if ( scrollTop < 0 ) return
                if ( Math.abs( scrollTop - lastScrollPosition.value ) < 80 ) return

                header.value = scrollSpace < 81 ? true : scrollTop < lastScrollPosition.value
                
                setTimeout(() => lastScrollPosition.value = scrollTop, 200 )

            }

            const routes = ['Login']

            onMounted(() => {
                window.onscroll = toggleHeader 
                window.onresize = toggleHeader
            })

            // Reset state

            const resetIntela = () => {

                router.push('/')
                store.commit('setQuery', '')
                store.dispatch('removeFilters')

            }

            return {
                user,
                menu,
                header,
                routes,
                logout,
                closeMenu,
                resetIntela,
                lastScrollPosition
            }

        },

        directives: { out }

    }

</script>