<template>
    
    <div>

        <!-- Slider body -->

        <div class="w-full flex relative" >

            <!-- Left nav button -->

            <div class="min-w-10 
                        flex
                        items-center 
                        justify-start
                        cursor-pointer"

            :class="{ 
                'opacity-25': leftBlock,
                'pointer-events-none': leftBlock
            }"

            v-if="nav"
                        
            @click="prev"

            >

                <img src="/images/icons/left-nav-arrow-black.svg" alt="Slide left" >

            </div>

            <!-- Slider -->

            <transition-group   
            
            tag="div"
            name="fade"
            class=" w-auto
                    grid
                    py-2
                    gap-5
                    relative
                    select-none
                    grid-flow-col
                    overflow-x-auto
                    hide-scroll-bar"

            :id="`slider-${id}`"

            @mousedown="setDrag"
            @mousemove="dragSlide"
            @mouseup="drag = false"
            @mouseleave="drag = false"

            >

                <slot name="slider" ></slot>

                <!-- end gap div -->

                <div class="w-4 h-full" key="end-slider-gap" />

            </transition-group>

            <!-- Right nav button -->

            <div class="min-w-10 
                        flex 
                        items-center 
                        justify-end
                        cursor-pointer" 

            :class="{ 
                'opacity-25': rightBlock,
                'pointer-events-none': rightBlock
            }"

            v-if="nav"

            @click="next"

            >

                <img src="/images/icons/right-nav-arrow-black.svg" alt="Slide left" >

            </div>

        </div>

    </div>

</template>

<script>

    import { ref, onMounted, onBeforeUnmount } from 'vue'

    import { horizontalScrollTo } from '../assets/scripts/scrollTo'

    export default {

        name: 'Slider',

        props: {

            center: Boolean,
            nav: { type: Boolean, default: true },
            id: { type: String, default: '0' }

        },

        setup( props ) {

            const leftBlock = ref(false)
            const rightBlock = ref(false)
            const drag = ref(false)
            const slider = ref(null)
            const startX = ref(0)
            const lastX = ref(0)

            const setProps = () => {

                slider.value = document.getElementById(`slider-${props.id}`)
                const scrollW = slider.value.scrollWidth
                const windowW = window.innerWidth

                if ( props.center ) slider.value.scrollLeft = ( ( scrollW - windowW ) / 2 )

                slider.value.addEventListener( 'scroll', checkSliderBlocks )

                checkSliderBlocks()

            }

            const setDrag = (e) => {

                drag.value = true

                lastX.value = slider.value.scrollLeft
                startX.value = e.pageX - slider.value.offsetLeft

            }

            const dragSlide = (e) => {

                if ( !drag.value ) return

                const x = e.pageX - slider.value.offsetLeft
                const walk = ( x - startX.value ) * 1 // multiply with higher number for faster drag scroll

                slider.value.scrollLeft = lastX.value - walk

                checkSliderBlocks()

            }

            const checkSliderBlocks = () => {

                slider.value.scrollLeft === 0 ? leftBlock.value = true : leftBlock.value = false
                slider.value.scrollWidth === ( slider.value.offsetWidth + slider.value.scrollLeft ) ? rightBlock.value = true : rightBlock.value = false

            }

            const prev = () => {

                horizontalScrollTo( slider.value, -slider.value.offsetWidth, 500 )

            }

            const next = () => {

                horizontalScrollTo( slider.value, slider.value.offsetWidth, 500 )

            }

            onMounted(() => {

                setProps()

                window.addEventListener( 'resize', setProps )

            })

            onBeforeUnmount(() => {

                window.removeEventListener( 'resize', setProps )
                slider.value.removeEventListener( 'scroll', checkSliderBlocks )

            })

            return {
                drag,
                prev,
                next,
                slider,
                setDrag,
                dragSlide,
                leftBlock,
                rightBlock
            }

        }

    }

</script>