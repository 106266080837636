export const debounce = function(func, interval) {

    let timeout

    return function() {

        const context = this, args = arguments

        clearTimeout(timeout)
        timeout = setTimeout(function() {

            timeout = null
            func.apply(context, args)

        }, interval)

    }

}