<template>
    
    <div @click="$router.push({ name: 'Details', params: { id: company.Edb } })" >

        <div class="h-auto py-8 md:py-6 bg-white flex space-x-6 items-center justify-between px-4 border-b border-solid border-gray-300 hover:bg-gray-100 cursor-pointer" >

            <div class="flex flex-col space-y-2" >

                <p>{{ company.NazivSubjekt }}</p>
                <p class="text-xs text-gray-500" >{{company.Adresa}}</p>

            </div>

            <div class="hidden md:flex space-x-6" >

                <p class="text-sm text-gray-500" >{{company.Embs}}</p>
                <img class=" min-w-6 max-w-6" src="/images/icons/arrow-right-blue.svg" alt="arrow" >

            </div>

        </div>

    </div>

</template>

<script>

    export default {
    
        name: 'Company Card',

        props: {
            company: Object
        }

    }

</script>