export const out = {

    mounted(el, binding) {

        document.addEventListener( 'click', function(e) {

            const isInside = el.contains( e.target )

            if ( !isInside ) binding.value()

        })

    }

}