<template>

    <div 

    id="filters"
    
    class="w-full relative h-64 bg-gray-200 transition-height duration-300"

    :class="{ 'overflow-hidden': filter?.type != 'select' }"

    :style="filtersHeight"
    
    >

        <div id="filter" class="w-full max-w-screen-xl mx-auto pt-4 px-4 pb-14 md:pt-10 md:pb-10 md:px-10" >

            <transition name="fade-up" mode="out-in" >

                <div class="grid sm:grid-cols-2 lg:grid-cols-4 md:gap-6" v-if="filter && filter.type === 'table'" >

                    <!-- Filter -->

                    <div :key="place" v-for="place in 4" class="flex flex-col space-y-1 md:space-y-6" >

                        <div 

                        :key="item.name" 
                        
                        v-for="item in filter.data.filter( ( e, i ) => i % gridRemainder === place - 1 )"

                        v-show="gridRemainder === 1 && activeItem ? item.id === activeItem.id : true"

                        >

                            <!-- Filter Head -->

                            <div class="h-8 md:h-14 bg-white rounded-lg flex items-center justify-start cursor-pointer select-none" :title="item.name" @click="item.selected ? false : setActiveItem(item)" >

                                <div class="filter-icon w-12 h-full flex items-center justify-center" :selected="item.selected" @click.stop="item.selected = !item.selected" >

                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="item.selected" >
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.29289L10 13.5858L7.70711 11.2929L6.29289 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.29289Z" fill="#4C6DDF"/>
                                    </svg>

                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else >
                                        <g opacity="0.66">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM17 11H13V7H11V11H7V13H11V17H13V13H17V11Z" fill="#7A809C"/>
                                        </g>
                                    </svg>

                                </div>

                                <p class="text-i-blue flex-grow truncate" >{{ formatTitle(item.name) }}</p>

                                <p class="text-sm text-gray-500 whitespace-no-wrap" ><span class="text-teal-500" v-if="item.states.filter( s => s.selected ).length > 0" >{{ item.states.filter( s => s.selected ).length }} /</span> {{ item.states.length }}</p>

                                <div 
                                
                                class="filter-icon w-12 h-full flex items-center justify-center transform transition-transform duration-150" 

                                :class="{
                                    'rotate-180': activeItem?.name === item.name
                                }"
                                
                                >

                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!item.selected" >
                                        <g opacity="0.66">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0775 6.07764L17.256 7.25615L10.0001 14.5121L2.74414 7.25615L3.92265 6.07764L10.0001 12.155L16.0775 6.07764Z" fill="#7A809C"/>
                                        </g>
                                    </svg>


                                </div>

                            </div>

                            <!-- Table Filter Body -->

                            <ul 
                            
                            class="grid grid-cols-2 col-gap-2 px-3 py-0 transition-all duration-300 overflow-hidden" 

                            :class="{
                                'py-2': activeItem?.name === item.name && !activeItem.selected
                            }"

                            :style="activeItem?.name === item.name && !activeItem.selected ? activeFilterHeight : 'height: 0px'"
                            
                            >

                                <li class="h-6 flex items-center hover:bg-gray-300 rounded-full space-x-2 cursor-pointer" :key="index" :title="state.name" v-for="( state, index ) in item.states" @click="state.selected = !state.selected" >

                                    <div class="filter-icon" :selected="state.selected" >

                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="state.selected" >
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.29289L10 13.5858L7.70711 11.2929L6.29289 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.29289Z" fill="#4C6DDF"/>
                                        </svg>

                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else >
                                            <g opacity="0.66">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="#7A809C"/>
                                            </g>
                                        </svg>

                                    </div>

                                    <p class="text-xs text-i-blue truncate" >{{ formatTitle(state.name) }}</p>

                                </li>

                            </ul>

                        </div>

                    </div>

                </div>

                <div class="flex flex-col space-y-4" v-else-if="filter && filter.type === 'select'" >

                    <nested-drop-list 

                    placeholder="Избери Сектор / Индустрија" 
                    searchPlaceholder="Барај по сектор, шифра, или индустрија" 
                    
                    :data="filter.groupsData"
                    
                    />

                </div>

                <div :key="filter.name" v-else-if="filter && filter.type === 'range'" >

                    <div class="flex space-x-2 md:space-x-4 items-center justify-center" >

                        <div class="flex items-center space-x-2 bg-white rounded-lg px-2 md:px-4" >

                            <p class="text-xs md:text-sm text-gray-500" >Од</p>

                            <input class="border-0 text-i-blue p-0" v-model="formatedRangeFrom" @keypress="preventLetters" v-if="filter.showCurrency" />

                            <input class="border-0 text-i-blue p-0" v-model="rangeFrom" @keypress="preventLetters" v-else />

                            <p class="text-xs md:text-sm text-gray-500" v-if="filter.showCurrency" >мкд</p>

                            <div class="filter-icon w-10 h-full hidden md:flex items-center justify-center" :selected="rangeFrom != null" >

                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="rangeFrom" >
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.29289L10 13.5858L7.70711 11.2929L6.29289 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.29289Z" fill="#4C6DDF"/>
                                </svg>

                            </div>

                        </div>

                        <p>-</p>

                        <div class="flex items-center space-x-2 bg-white rounded-lg px-2 md:px-4" >

                            <p class="text-xs md:text-sm text-gray-500" >До</p>

                            <input class="border-0 text-i-blue p-0" v-model="formatedRangeTo" @keypress="preventLetters" v-if="filter.showCurrency" />

                            <input class="border-0 text-i-blue p-0" v-model="rangeTo" @keypress="preventLetters" v-else />

                            <p class="text-xs md:text-sm text-gray-500" v-if="filter.showCurrency" >мкд</p>

                            <div class="filter-icon w-10 h-full hidden md:flex items-center justify-center" :selected="rangeTo != null" >

                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="rangeTo" >
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.29289L10 13.5858L7.70711 11.2929L6.29289 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.29289Z" fill="#4C6DDF"/>
                                </svg>

                            </div>

                        </div>

                    </div>

                </div>

            </transition>

        </div>

    </div>

    <div 
    
    class="bg-white flex space-x-2 absolute bottom-0 left-1/2 transform -translate-x-1/2 md:translate-y-1/2 py-1 px-1 md:px-4 rounded-full rounded-b-none md:rounded-b-full border border-solid border-gray-200 select-none cursor-pointer" 

    @click="$emit('close'), activeItem = null"

    v-if="filter"

    >

        <div class="transform rotate-180" >

            <img src="/images/icons/chevron-bottom.svg" alt="chevron" >

        </div>

        <p class="text-sm text-gray-600 hidden md:block" >Затвори филтри</p>

    </div>

</template>

<script>

    import { ref, watch, computed, onMounted } from 'vue'

    import { formatTitle } from '@/assets/scripts/formatTitle'
    // import dropList from '@/components/DropList'
    import nestedDropList from '@/components/nestedDropList'


    export default {
    
        name: 'Filters',

        emits: ['close', 'update:selected', 'update:from', 'update:to'],

        components: { 
            //dropList, 
            nestedDropList 
        },

        props: {
            selected: Array,
            filter: Object,
            from: [Number, String],
            to: [Number, String]
        },

        setup(props, {emit}) {

            const height = ref(0)
            const filtersHeight = computed(() => props.filter ? `height: ${height.value}px;` : 'height: 0px;' )

            const getFiltersHeight = async ( delay = 0 ) => {

                let elementHeight = new Promise((resolve) => {

                    setTimeout(() => { 

                        const value = document.getElementById('filter')?.clientHeight || 0
 
                        resolve( value )

                    }, delay)

                })

                await elementHeight
                return elementHeight

            }

            watch(() => props.filter, async( n, o ) => { // Set filters bar height when filter is toggled

                if ( n && !o ) height.value = await getFiltersHeight()
                else height.value = await getFiltersHeight(350)

            }, { immediate: true, deep: true })

            // Table filters

            const gridRemainder = ref(4)

            const setGridRemainder = async () => {

                const windowWidth = window.innerWidth

                if ( windowWidth > 640 && windowWidth < 1024 ) gridRemainder.value = 2

                else if ( windowWidth > 1024 ) gridRemainder.value = 4

                else gridRemainder.value = 1

                height.value = await getFiltersHeight()

            }

            onMounted(() => { // Change remainder on diferent window widths

                setGridRemainder()

                window.onresize = setGridRemainder

            })

            const activeItem = ref(null)

            const activeFilterLength = computed(() => 

                activeItem.value && !activeItem.value.selected ? 
                activeItem.value.states.length % 2 === 0 ? 
                activeItem.value.states.length / 2 : 
                ( activeItem.value.states.length + 1 ) / 2 : 
                0  

            )

            const activeFilterHeight = computed(() => 

                activeItem.value ? 
                `height: ${ (activeFilterLength.value * 24) + 10 }px` : 
                `height: 0px` 

            )

            watch( activeFilterLength, async ( n, o ) => { // Set filters height when filter is changed

                const newHeight = await getFiltersHeight(0)

                if ( n > 0 && o === 0 ) {

                    height.value = newHeight + (n * 24) + 10

                }

                else if ( n > 0 && o > 0 ) {

                    height.value = newHeight - ((o - n) * 24) + 10

                }

                else height.value = newHeight - ((o * 24) + 10)

            })

            const setActiveItem = (item) => {

                activeItem.value?.name === item.name ? activeItem.value = null : activeItem.value = item

            }

            // Select Filters

            const selectedList = computed({
                get: () => props.selected,
                set: value => emit('update:selected', value )
            })

            // Range Filters

            const preventLetters = (evt) => {

                let theEvent = evt || window.event

                // Handle paste

                if (theEvent.type === 'paste') {

                    key = event.clipboardData.getData('text/plain')

                } 
                
                else {

                    // Handle key press

                    var key = theEvent.keyCode || theEvent.which
                    key = String.fromCharCode(key)

                }

                const regex = /[0-9]|\./

                if ( !regex.test(key) ) {

                    theEvent.returnValue = false

                    if (theEvent.preventDefault) theEvent.preventDefault()

                }

            }

            const formater = new Intl.NumberFormat('mk-MK')

            const rangeFrom = computed({
                get: () => props.from,
                set: value => emit('update:from', (value || value === 0) ? value : null )
            })

            const formatedRangeFrom = computed({
                get: () => props.from ? formater.format( props.from.replace(/[^0-9]/g, '') ) : null,
                set: value => emit('update:from', value.replace(/[^0-9]/g, '') )
            })

            const rangeTo = computed({
                get: () => props.to,
                set: value => emit('update:to', (value || value === 0) ? value : null )
            })

            const formatedRangeTo = computed({
                get: () => props.to ? formater.format( props.to.replace(/[^0-9]/g, '') ) : null,
                set: value => emit('update:to', value.replace(/[^0-9]/g, '') )
            })

            return {
                rangeTo,
                rangeFrom,
                activeItem,
                formatTitle,
                selectedList,
                setActiveItem,
                filtersHeight,
                gridRemainder,
                preventLetters,
                formatedRangeTo,
                formatedRangeFrom,
                activeFilterLength,
                activeFilterHeight
            }

        }

    }

</script>

<style scoped >

    path {
        fill: rgba( 0 0 0 / 0.2 );
    }

    .filter-icon:hover path {
        fill: rgba( 0 0 0 / 0.4 );
    }

    .filter-icon[selected=true] path, .filter-icon[selected=true]:hover path {
        fill: #4C6DDF;
    }

    .fade-up-enter-active, .fade-up-leave-active {

        transition: 0.3s ease;
    }

    .fade-up-leave-to {

        transform: translate3d( 0px, -30px, 0px );
        opacity: 0;
    }

    .fade-up-enter-from {

        transform: translate3d( 0px, 30px, 0px );
        opacity: 0;
    }

</style>